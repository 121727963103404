$(function(){
    initMobileMenu();

    if ($('#popUpsContainer').length) {
        setTimeout(function () {
            var items = [];
            $('#popUpsContainer').find('.uno-mfp-popup').each(function () {
                items.push({
                    src: $(this)
                });
            });
            $.magnificPopup.open({
                items: items,
                type: 'inline',
                gallery: {
                    enabled: true
                }
            });
        }, 5000);
    }

    $('.btnCloseMagnific').click(function(){
        $.magnificPopup.close();
    });

    initSearchBar();


    function initSearchBar() {
        $('#search').click(function(){
            var $searchBar = $('#searchBar');
            if($(this).data('opened') != 1) {
                $(this).data('opened', 1);
                $(this).addClass('active');
                $(this).find('.fa').removeClass('fa-search').addClass('fa-times');
                $searchBar.fadeIn();
                $searchBar.find('input[type="text"]').focus();
            }else{
                $(this).data('opened', 0);
                $(this).removeClass('active');
                $(this).find('.fa').removeClass('fa-times').addClass('fa-search');
                $searchBar.fadeOut();
            }
        });
    }

    $('#menu nav li:has(ul)').doubleTapToGo();

    imgToBG($('.bg-img'), '.img-bg');

    $("#setia-footer-button").click(function () {
        $("html, body").animate({scrollTop: $("#wrapper").offset().top}, "1000");
        return false;
    });
});

function initMobileMenu() {
    var menu = $('#menu nav').clone(),
        mobileMenu = $('#mobile-menu');
    mobileMenu.append(menu.html()).mmenu({
        offCanvas: {
            position: "right"
        }
    });
}

function imgToBG(ele, _imgClass){
    if(ele.length > 0){
        ele.each(function(){
            var _this = $(this), img = _this.find(_imgClass).eq(0) || _this.find('img').eq(0);
            _this.css({
                'background': 'url("'+img.attr('src')+'")', 'background-repeat':'no-repeat'
            });
            img.hide();
        });
    }
}
